import React from 'react'
import Landing_page_navbar from './Landing_page_navbar'
import Collapse from 'react-bootstrap/Collapse';
import { useState } from 'react';
import Footer from './Footer';
import Backtotop from '../pages/Backtotop';
import { useNavigate } from 'react-router-dom';

const FAQ = () => {
  const navigate=useNavigate()

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);
    const [open7, setOpen7] = useState(false);
    const [open8, setOpen8] = useState(false);

  return (
    <div>
        <Landing_page_navbar/>
        <div className='mt-3 row m-0 px-2 px-md-5 justify-content-center faq-div py-4 py-md-3' style={{backgroundColor:'#F3F0FF'}}>
                <div className='col-6 col-md-2 d-flex flex-column align-items-center justify-content-center'>
                   <img src={require('../img/landing_page/Group 405.png')}  alt="" />
                    <p  onClick={()=>{
                      navigate('/getting_started')
                    }}style={{fontSize:'17px',color:'#2A3941',cursor:'pointer'}} className='our-team-text4 mt-2'>Getting Started</p>
                </div>
                  <div className='col-6 col-md-2 d-flex flex-column align-items-center justify-content-center'>
                    <img src={require('../img/landing_page/Group 404.png')}  alt="" />
                    <p onClick={()=>{
                      navigate('/product_guidelines')
                    }} style={{fontSize:'17px',color:'#2A3941',cursor:'pointer'}} className='our-team-text4 mt-2'>Product Guides</p>
                </div> 
                <div className='col-6 col-md-2 d-flex flex-column align-items-center justify-content-center'>
                    <img src={require('../img/landing_page/Group 403.png')}  alt="" />
                    <p  onClick={()=>{
                      navigate('/faq')
                    }} style={{fontSize:'17px',color:'#5d5fe3',cursor:'pointer',borderBottom:'2px solid #5d5fe3'}} className='our-team-text4 mt-2'>Help & FAQs</p>
                </div>
                <div className='col-6 col-md-2 d-flex flex-column align-items-center justify-content-center'>
                    <img src={require('../img/landing_page/Group 402.png')}  alt="" />
                    <p onClick={()=>{
                      navigate('/Technical_Guidelines')
                    }} style={{fontSize:'17px',color:'#2A3941',cursor:'pointer'}} className='our-team-text4 mt-2'>Technical Guides</p>
                </div>
                <div className='col-6 col-md-2 d-flex flex-column align-items-center justify-content-center'>
                    <img src={require('../img/landing_page/Group 401.png')}  alt="" />
                    <p onClick={()=>{
                      navigate('/videos')
                    }} style={{fontSize:'17px',color:'#2A3941',cursor:'pointer'}} className='our-team-text4 mt-2'>Webinars</p>
                </div>
                <div className='col-6 col-md-2 d-flex flex-column align-items-center justify-content-center'>
                    <img src={require('../img/landing_page/Group 400.png')}  alt="" />
                    <p onClick={()=>{
                      navigate('/community')
                    }} style={{fontSize:'17px',color:'#2a3941',cursor:'pointer'}} className='our-team-text4 mt-2'>Community</p>
                </div>
            </div>

            <div className='pb-5' style={{backgroundColor:'#F9F9FB'}}>
                <div className='container'>
                <div className=' py-3 pt-4'>
                    <p className='privacy-policy-head2'>Common Issues and Solutions</p>

                  <div className=''>
                    <p className='d-flex justify-content-between border-bottom align-items-center m-0 border-top py-3' style={{cursor:'pointer'}} onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}><span className='privacy-policy-text'>I'm having trouble logging in. What should I do?</span> <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path d="M14.375 20.625H15.625V15.625H20.625V14.375H15.625V9.375H14.375V14.375H9.375V15.625H14.375V20.625ZM15.0041 26.25C13.4484 26.25 11.9858 25.9548 10.6163 25.3644C9.24687 24.774 8.05562 23.9727 7.0425 22.9606C6.02938 21.9485 5.2274 20.7583 4.63656 19.39C4.04552 18.0219 3.75 16.5599 3.75 15.0041C3.75 13.4484 4.04521 11.9858 4.63562 10.6162C5.22604 9.24687 6.02729 8.05562 7.03937 7.0425C8.05146 6.02937 9.24167 5.2274 10.61 4.63656C11.9781 4.04552 13.4401 3.75 14.9959 3.75C16.5516 3.75 18.0142 4.04521 19.3838 4.63563C20.7531 5.22604 21.9444 6.02729 22.9575 7.03938C23.9706 8.05146 24.7726 9.24167 25.3634 10.61C25.9545 11.9781 26.25 13.4401 26.25 14.9959C26.25 16.5516 25.9548 18.0142 25.3644 19.3837C24.774 20.7531 23.9727 21.9444 22.9606 22.9575C21.9485 23.9706 20.7583 24.7726 19.39 25.3634C18.0219 25.9545 16.5599 26.25 15.0041 26.25ZM15 25C17.7917 25 20.1562 24.0312 22.0938 22.0938C24.0312 20.1562 25 17.7917 25 15C25 12.2083 24.0312 9.84375 22.0938 7.90625C20.1562 5.96875 17.7917 5 15 5C12.2083 5 9.84375 5.96875 7.90625 7.90625C5.96875 9.84375 5 12.2083 5 15C5 17.7917 5.96875 20.1562 7.90625 22.0938C9.84375 24.0312 12.2083 25 15 25Z" fill="#5D5FE3"/>
                      </svg></span></p>
              
                      <p></p>
                      <Collapse in={open} className=''>
                        <div id="example-collapse-text">
                        Ensure you're using your university email and password. If you've forgotten your password, click "Forgot Password" to reset it.
                        </div>
                      </Collapse>
                    </div>

                    <div className=''>
                    <p className='d-flex justify-content-between border-bottom align-items-center m-0 border-top py-3' style={{cursor:'pointer'}} onClick={() => setOpen2(!open2)}
                          aria-controls="example-collapse-text"
                          aria-expanded={open2}><span className='privacy-policy-text'>Why can't I upload a document?</span> <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                          <path d="M14.375 20.625H15.625V15.625H20.625V14.375H15.625V9.375H14.375V14.375H9.375V15.625H14.375V20.625ZM15.0041 26.25C13.4484 26.25 11.9858 25.9548 10.6163 25.3644C9.24687 24.774 8.05562 23.9727 7.0425 22.9606C6.02938 21.9485 5.2274 20.7583 4.63656 19.39C4.04552 18.0219 3.75 16.5599 3.75 15.0041C3.75 13.4484 4.04521 11.9858 4.63562 10.6162C5.22604 9.24687 6.02729 8.05562 7.03937 7.0425C8.05146 6.02937 9.24167 5.2274 10.61 4.63656C11.9781 4.04552 13.4401 3.75 14.9959 3.75C16.5516 3.75 18.0142 4.04521 19.3838 4.63563C20.7531 5.22604 21.9444 6.02729 22.9575 7.03938C23.9706 8.05146 24.7726 9.24167 25.3634 10.61C25.9545 11.9781 26.25 13.4401 26.25 14.9959C26.25 16.5516 25.9548 18.0142 25.3644 19.3837C24.774 20.7531 23.9727 21.9444 22.9606 22.9575C21.9485 23.9706 20.7583 24.7726 19.39 25.3634C18.0219 25.9545 16.5599 26.25 15.0041 26.25ZM15 25C17.7917 25 20.1562 24.0312 22.0938 22.0938C24.0312 20.1562 25 17.7917 25 15C25 12.2083 24.0312 9.84375 22.0938 7.90625C20.1562 5.96875 17.7917 5 15 5C12.2083 5 9.84375 5.96875 7.90625 7.90625C5.96875 9.84375 5 12.2083 5 15C5 17.7917 5.96875 20.1562 7.90625 22.0938C9.84375 24.0312 12.2083 25 15 25Z" fill="#5D5FE3"/>
                        </svg></span></p>
                                
                        <p></p>
                        <Collapse in={open2} className=''>
                          <div id="example-collapse-text">
                          Check that your file meets the size and format requirements and that you're not exceeding your storage limit.
                          </div>
                        </Collapse>
                    </div>
               

                    <div className=''>
                    <p className='d-flex justify-content-between border-bottom align-items-center m-0 border-top py-3' style={{cursor:'pointer'}} onClick={() => setOpen2(!open2)}
                          aria-controls="example-collapse-text"
                          aria-expanded={open2}><span className='privacy-policy-text'>Is my personal information visible to others?</span> <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                          <path d="M14.375 20.625H15.625V15.625H20.625V14.375H15.625V9.375H14.375V14.375H9.375V15.625H14.375V20.625ZM15.0041 26.25C13.4484 26.25 11.9858 25.9548 10.6163 25.3644C9.24687 24.774 8.05562 23.9727 7.0425 22.9606C6.02938 21.9485 5.2274 20.7583 4.63656 19.39C4.04552 18.0219 3.75 16.5599 3.75 15.0041C3.75 13.4484 4.04521 11.9858 4.63562 10.6162C5.22604 9.24687 6.02729 8.05562 7.03937 7.0425C8.05146 6.02937 9.24167 5.2274 10.61 4.63656C11.9781 4.04552 13.4401 3.75 14.9959 3.75C16.5516 3.75 18.0142 4.04521 19.3838 4.63563C20.7531 5.22604 21.9444 6.02729 22.9575 7.03938C23.9706 8.05146 24.7726 9.24167 25.3634 10.61C25.9545 11.9781 26.25 13.4401 26.25 14.9959C26.25 16.5516 25.9548 18.0142 25.3644 19.3837C24.774 20.7531 23.9727 21.9444 22.9606 22.9575C21.9485 23.9706 20.7583 24.7726 19.39 25.3634C18.0219 25.9545 16.5599 26.25 15.0041 26.25ZM15 25C17.7917 25 20.1562 24.0312 22.0938 22.0938C24.0312 20.1562 25 17.7917 25 15C25 12.2083 24.0312 9.84375 22.0938 7.90625C20.1562 5.96875 17.7917 5 15 5C12.2083 5 9.84375 5.96875 7.90625 7.90625C5.96875 9.84375 5 12.2083 5 15C5 17.7917 5.96875 20.1562 7.90625 22.0938C9.84375 24.0312 12.2083 25 15 25Z" fill="#5D5FE3"/>
                        </svg></span></p>
                                
                        <p></p>
                        <Collapse in={open2} className=''>
                          <div id="example-collapse-text">
                          Nope! Your first name, last name, and university email are safely locked away. Only your university name, course, city, and a fun nickname (yes, you can get creative here!) are visible to others. For more on data visibility, check out our Privacy Policy.

                          </div>
                        </Collapse>
                    </div>

                    <div className=''>
                    <p className='d-flex justify-content-between border-bottom align-items-center m-0 border-top py-3' style={{cursor:'pointer'}} onClick={() => setOpen2(!open2)}
                          aria-controls="example-collapse-text"
                          aria-expanded={open2}><span className='privacy-policy-text'>What can I do on the platform?</span> <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                          <path d="M14.375 20.625H15.625V15.625H20.625V14.375H15.625V9.375H14.375V14.375H9.375V15.625H14.375V20.625ZM15.0041 26.25C13.4484 26.25 11.9858 25.9548 10.6163 25.3644C9.24687 24.774 8.05562 23.9727 7.0425 22.9606C6.02938 21.9485 5.2274 20.7583 4.63656 19.39C4.04552 18.0219 3.75 16.5599 3.75 15.0041C3.75 13.4484 4.04521 11.9858 4.63562 10.6162C5.22604 9.24687 6.02729 8.05562 7.03937 7.0425C8.05146 6.02937 9.24167 5.2274 10.61 4.63656C11.9781 4.04552 13.4401 3.75 14.9959 3.75C16.5516 3.75 18.0142 4.04521 19.3838 4.63563C20.7531 5.22604 21.9444 6.02729 22.9575 7.03938C23.9706 8.05146 24.7726 9.24167 25.3634 10.61C25.9545 11.9781 26.25 13.4401 26.25 14.9959C26.25 16.5516 25.9548 18.0142 25.3644 19.3837C24.774 20.7531 23.9727 21.9444 22.9606 22.9575C21.9485 23.9706 20.7583 24.7726 19.39 25.3634C18.0219 25.9545 16.5599 26.25 15.0041 26.25ZM15 25C17.7917 25 20.1562 24.0312 22.0938 22.0938C24.0312 20.1562 25 17.7917 25 15C25 12.2083 24.0312 9.84375 22.0938 7.90625C20.1562 5.96875 17.7917 5 15 5C12.2083 5 9.84375 5.96875 7.90625 7.90625C5.96875 9.84375 5 12.2083 5 15C5 17.7917 5.96875 20.1562 7.90625 22.0938C9.84375 24.0312 12.2083 25 15 25Z" fill="#5D5FE3"/>
                        </svg></span></p>
                                
                        <p></p>
                        <Collapse in={open2} className=''>
                          <div id="example-collapse-text">
                          Think of it as your academic toolkit: you can upload notes, create flashcards, join discussions, and even highlight documents with burning questions. Basically, all the things you wish you had during that last-minute cram session!

                          </div>
                        </Collapse>
                    </div>

                </div>

                

                <div className=' py-3'>
                    <p className='privacy-policy-head2'>Documents and Content </p>

                    <div className=''>
                    <p className='d-flex justify-content-between border-bottom align-items-center m-0 border-top py-3' style={{cursor:'pointer'}} onClick={() => setOpen3(!open3)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open3}><span className='privacy-policy-text'>Who can see the study materials I upload?</span> <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                      <path d="M14.375 20.625H15.625V15.625H20.625V14.375H15.625V9.375H14.375V14.375H9.375V15.625H14.375V20.625ZM15.0041 26.25C13.4484 26.25 11.9858 25.9548 10.6163 25.3644C9.24687 24.774 8.05562 23.9727 7.0425 22.9606C6.02938 21.9485 5.2274 20.7583 4.63656 19.39C4.04552 18.0219 3.75 16.5599 3.75 15.0041C3.75 13.4484 4.04521 11.9858 4.63562 10.6162C5.22604 9.24687 6.02729 8.05562 7.03937 7.0425C8.05146 6.02937 9.24167 5.2274 10.61 4.63656C11.9781 4.04552 13.4401 3.75 14.9959 3.75C16.5516 3.75 18.0142 4.04521 19.3838 4.63563C20.7531 5.22604 21.9444 6.02729 22.9575 7.03938C23.9706 8.05146 24.7726 9.24167 25.3634 10.61C25.9545 11.9781 26.25 13.4401 26.25 14.9959C26.25 16.5516 25.9548 18.0142 25.3644 19.3837C24.774 20.7531 23.9727 21.9444 22.9606 22.9575C21.9485 23.9706 20.7583 24.7726 19.39 25.3634C18.0219 25.9545 16.5599 26.25 15.0041 26.25ZM15 25C17.7917 25 20.1562 24.0312 22.0938 22.0938C24.0312 20.1562 25 17.7917 25 15C25 12.2083 24.0312 9.84375 22.0938 7.90625C20.1562 5.96875 17.7917 5 15 5C12.2083 5 9.84375 5.96875 7.90625 7.90625C5.96875 9.84375 5 12.2083 5 15C5 17.7917 5.96875 20.1562 7.90625 22.0938C9.84375 24.0312 12.2083 25 15 25Z" fill="#5D5FE3"/>
                    </svg></span></p>
              
                    <p></p>
                    <Collapse in={open3} className='pb-3'>
                      <div id="example-collapse-text">
                      Only students from your university get to check out your uploads. So, no peeking from other universities—your content stays where it’s supposed to! For more on content sharing and visibility, see our Terms and Conditions.

                      </div>
                    </Collapse>
                    </div>

                    <div className=''>
                    <p className='d-flex justify-content-between border-bottom align-items-center m-0 border-top py-3' style={{cursor:'pointer'}} onClick={() => setOpen4(!open4)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open4}><span className='privacy-policy-text'>What types of documents can I upload?</span> <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                      <path d="M14.375 20.625H15.625V15.625H20.625V14.375H15.625V9.375H14.375V14.375H9.375V15.625H14.375V20.625ZM15.0041 26.25C13.4484 26.25 11.9858 25.9548 10.6163 25.3644C9.24687 24.774 8.05562 23.9727 7.0425 22.9606C6.02938 21.9485 5.2274 20.7583 4.63656 19.39C4.04552 18.0219 3.75 16.5599 3.75 15.0041C3.75 13.4484 4.04521 11.9858 4.63562 10.6162C5.22604 9.24687 6.02729 8.05562 7.03937 7.0425C8.05146 6.02937 9.24167 5.2274 10.61 4.63656C11.9781 4.04552 13.4401 3.75 14.9959 3.75C16.5516 3.75 18.0142 4.04521 19.3838 4.63563C20.7531 5.22604 21.9444 6.02729 22.9575 7.03938C23.9706 8.05146 24.7726 9.24167 25.3634 10.61C25.9545 11.9781 26.25 13.4401 26.25 14.9959C26.25 16.5516 25.9548 18.0142 25.3644 19.3837C24.774 20.7531 23.9727 21.9444 22.9606 22.9575C21.9485 23.9706 20.7583 24.7726 19.39 25.3634C18.0219 25.9545 16.5599 26.25 15.0041 26.25ZM15 25C17.7917 25 20.1562 24.0312 22.0938 22.0938C24.0312 20.1562 25 17.7917 25 15C25 12.2083 24.0312 9.84375 22.0938 7.90625C20.1562 5.96875 17.7917 5 15 5C12.2083 5 9.84375 5.96875 7.90625 7.90625C5.96875 9.84375 5 12.2083 5 15C5 17.7917 5.96875 20.1562 7.90625 22.0938C9.84375 24.0312 12.2083 25 15 25Z" fill="#5D5FE3"/>
                    </svg></span></p>
              
                    <p></p>
                    <Collapse in={open4} className='pb-3'>
                      <div id="example-collapse-text">
                      You can upload personal notes, lecture slides (Vorlesungen), exam papers, solutions, or anything else that can help you and your fellow students survive exams. If students want to keep things interesting and it fits within our community guidelines and terms, memes are welcome too! And yes, uploaded documents are downloadable! Learn more about acceptable content in our Terms and Conditions.

                      </div>
                    </Collapse>
                    </div>


                    <div className=''>
                    <p className='d-flex justify-content-between border-bottom align-items-center m-0 border-top py-3' style={{cursor:'pointer'}} onClick={() => setOpen4(!open4)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open4}><span className='privacy-policy-text'>Are uploaded documents reviewed by an admin?</span> <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                      <path d="M14.375 20.625H15.625V15.625H20.625V14.375H15.625V9.375H14.375V14.375H9.375V15.625H14.375V20.625ZM15.0041 26.25C13.4484 26.25 11.9858 25.9548 10.6163 25.3644C9.24687 24.774 8.05562 23.9727 7.0425 22.9606C6.02938 21.9485 5.2274 20.7583 4.63656 19.39C4.04552 18.0219 3.75 16.5599 3.75 15.0041C3.75 13.4484 4.04521 11.9858 4.63562 10.6162C5.22604 9.24687 6.02729 8.05562 7.03937 7.0425C8.05146 6.02937 9.24167 5.2274 10.61 4.63656C11.9781 4.04552 13.4401 3.75 14.9959 3.75C16.5516 3.75 18.0142 4.04521 19.3838 4.63563C20.7531 5.22604 21.9444 6.02729 22.9575 7.03938C23.9706 8.05146 24.7726 9.24167 25.3634 10.61C25.9545 11.9781 26.25 13.4401 26.25 14.9959C26.25 16.5516 25.9548 18.0142 25.3644 19.3837C24.774 20.7531 23.9727 21.9444 22.9606 22.9575C21.9485 23.9706 20.7583 24.7726 19.39 25.3634C18.0219 25.9545 16.5599 26.25 15.0041 26.25ZM15 25C17.7917 25 20.1562 24.0312 22.0938 22.0938C24.0312 20.1562 25 17.7917 25 15C25 12.2083 24.0312 9.84375 22.0938 7.90625C20.1562 5.96875 17.7917 5 15 5C12.2083 5 9.84375 5.96875 7.90625 7.90625C5.96875 9.84375 5 12.2083 5 15C5 17.7917 5.96875 20.1562 7.90625 22.0938C9.84375 24.0312 12.2083 25 15 25Z" fill="#5D5FE3"/>
                    </svg></span></p>
              
                    <p></p>
                    <Collapse in={open4} className='pb-3'>
                      <div id="example-collapse-text">
                      We trust you! So, no, an admin won’t peek at your notes before they go live. But we do regular checks to make sure no one’s sneaking in any inappropriate content. Memes are cool, but they need to stick to the community guidelines!
For more details on moderation, visit our Terms and Conditions.


                      </div>
                    </Collapse>
                    </div>
                    <div className=''>
                    <p className='d-flex justify-content-between border-bottom align-items-center m-0 border-top py-3' style={{cursor:'pointer'}} onClick={() => setOpen4(!open4)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open4}><span className='privacy-policy-text'> Can I edit or delete a document after uploading it?</span> <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                      <path d="M14.375 20.625H15.625V15.625H20.625V14.375H15.625V9.375H14.375V14.375H9.375V15.625H14.375V20.625ZM15.0041 26.25C13.4484 26.25 11.9858 25.9548 10.6163 25.3644C9.24687 24.774 8.05562 23.9727 7.0425 22.9606C6.02938 21.9485 5.2274 20.7583 4.63656 19.39C4.04552 18.0219 3.75 16.5599 3.75 15.0041C3.75 13.4484 4.04521 11.9858 4.63562 10.6162C5.22604 9.24687 6.02729 8.05562 7.03937 7.0425C8.05146 6.02937 9.24167 5.2274 10.61 4.63656C11.9781 4.04552 13.4401 3.75 14.9959 3.75C16.5516 3.75 18.0142 4.04521 19.3838 4.63563C20.7531 5.22604 21.9444 6.02729 22.9575 7.03938C23.9706 8.05146 24.7726 9.24167 25.3634 10.61C25.9545 11.9781 26.25 13.4401 26.25 14.9959C26.25 16.5516 25.9548 18.0142 25.3644 19.3837C24.774 20.7531 23.9727 21.9444 22.9606 22.9575C21.9485 23.9706 20.7583 24.7726 19.39 25.3634C18.0219 25.9545 16.5599 26.25 15.0041 26.25ZM15 25C17.7917 25 20.1562 24.0312 22.0938 22.0938C24.0312 20.1562 25 17.7917 25 15C25 12.2083 24.0312 9.84375 22.0938 7.90625C20.1562 5.96875 17.7917 5 15 5C12.2083 5 9.84375 5.96875 7.90625 7.90625C5.96875 9.84375 5 12.2083 5 15C5 17.7917 5.96875 20.1562 7.90625 22.0938C9.84375 24.0312 12.2083 25 15 25Z" fill="#5D5FE3"/>
                    </svg></span></p>
              
                    <p></p>
                    <Collapse in={open4} className='pb-3'>
                      <div id="example-collapse-text">
                      Once your document is live, it’s like sending a message in a bottle—it’s out there! This helps ensure all the great discussions around your content don’t vanish. But no worries, you can preview your document before uploading to make sure everything is perfect. If you really need to delete it, just drop us a message via the Contact Page and we’ll help you out!



                      </div>
                    </Collapse>
                    </div>


                </div>

                <div className=' py-3'>
                    <p className='privacy-policy-head2'>Contacting Support</p>

                    <div className=''>
                    <p className='d-flex justify-content-between border-bottom align-items-center m-0 border-top py-3' style={{cursor:'pointer'}} onClick={() => setOpen5(!open5)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open5}><span className='privacy-policy-text'>How do I contact customer support?</span> <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                      <path d="M14.375 20.625H15.625V15.625H20.625V14.375H15.625V9.375H14.375V14.375H9.375V15.625H14.375V20.625ZM15.0041 26.25C13.4484 26.25 11.9858 25.9548 10.6163 25.3644C9.24687 24.774 8.05562 23.9727 7.0425 22.9606C6.02938 21.9485 5.2274 20.7583 4.63656 19.39C4.04552 18.0219 3.75 16.5599 3.75 15.0041C3.75 13.4484 4.04521 11.9858 4.63562 10.6162C5.22604 9.24687 6.02729 8.05562 7.03937 7.0425C8.05146 6.02937 9.24167 5.2274 10.61 4.63656C11.9781 4.04552 13.4401 3.75 14.9959 3.75C16.5516 3.75 18.0142 4.04521 19.3838 4.63563C20.7531 5.22604 21.9444 6.02729 22.9575 7.03938C23.9706 8.05146 24.7726 9.24167 25.3634 10.61C25.9545 11.9781 26.25 13.4401 26.25 14.9959C26.25 16.5516 25.9548 18.0142 25.3644 19.3837C24.774 20.7531 23.9727 21.9444 22.9606 22.9575C21.9485 23.9706 20.7583 24.7726 19.39 25.3634C18.0219 25.9545 16.5599 26.25 15.0041 26.25ZM15 25C17.7917 25 20.1562 24.0312 22.0938 22.0938C24.0312 20.1562 25 17.7917 25 15C25 12.2083 24.0312 9.84375 22.0938 7.90625C20.1562 5.96875 17.7917 5 15 5C12.2083 5 9.84375 5.96875 7.90625 7.90625C5.96875 9.84375 5 12.2083 5 15C5 17.7917 5.96875 20.1562 7.90625 22.0938C9.84375 24.0312 12.2083 25 15 25Z" fill="#5D5FE3"/>
                    </svg></span></p>
                            
                    <p></p>
                    <Collapse in={open5} className=''>
                      <div id="example-collapse-text">
                      You can reach us via email at info@lernen-hub.de or through the "Contact Us" form on the website.
                      </div>
                    </Collapse>
                    </div>

                    <div className=''>
                    <p className='d-flex justify-content-between border-bottom align-items-center m-0 border-top py-3' style={{cursor:'pointer'}} onClick={() => setOpen6(!open6)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open6}><span className='privacy-policy-text'>What is the response time for support queries?</span> <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                      <path d="M14.375 20.625H15.625V15.625H20.625V14.375H15.625V9.375H14.375V14.375H9.375V15.625H14.375V20.625ZM15.0041 26.25C13.4484 26.25 11.9858 25.9548 10.6163 25.3644C9.24687 24.774 8.05562 23.9727 7.0425 22.9606C6.02938 21.9485 5.2274 20.7583 4.63656 19.39C4.04552 18.0219 3.75 16.5599 3.75 15.0041C3.75 13.4484 4.04521 11.9858 4.63562 10.6162C5.22604 9.24687 6.02729 8.05562 7.03937 7.0425C8.05146 6.02937 9.24167 5.2274 10.61 4.63656C11.9781 4.04552 13.4401 3.75 14.9959 3.75C16.5516 3.75 18.0142 4.04521 19.3838 4.63563C20.7531 5.22604 21.9444 6.02729 22.9575 7.03938C23.9706 8.05146 24.7726 9.24167 25.3634 10.61C25.9545 11.9781 26.25 13.4401 26.25 14.9959C26.25 16.5516 25.9548 18.0142 25.3644 19.3837C24.774 20.7531 23.9727 21.9444 22.9606 22.9575C21.9485 23.9706 20.7583 24.7726 19.39 25.3634C18.0219 25.9545 16.5599 26.25 15.0041 26.25ZM15 25C17.7917 25 20.1562 24.0312 22.0938 22.0938C24.0312 20.1562 25 17.7917 25 15C25 12.2083 24.0312 9.84375 22.0938 7.90625C20.1562 5.96875 17.7917 5 15 5C12.2083 5 9.84375 5.96875 7.90625 7.90625C5.96875 9.84375 5 12.2083 5 15C5 17.7917 5.96875 20.1562 7.90625 22.0938C9.84375 24.0312 12.2083 25 15 25Z" fill="#5D5FE3"/>
                    </svg></span></p>
                            
                    <p></p>
                    <Collapse in={open6} className=''>
                      <div id="example-collapse-text">
                      We aim to respond to all inquiries within 72 hours.
                      </div>
                    </Collapse>
                    </div>
               
                </div>


                <div className=' py-3'>
                    <p className='privacy-policy-head2'>Understanding Features</p>

                    <div className=''>
                    <p className='d-flex justify-content-between border-bottom align-items-center m-0 border-top py-3' style={{cursor:'pointer'}} onClick={() => setOpen7(!open7)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open7}><span className='privacy-policy-text'>How do I suggest a new feature?</span> <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path d="M14.375 20.625H15.625V15.625H20.625V14.375H15.625V9.375H14.375V14.375H9.375V15.625H14.375V20.625ZM15.0041 26.25C13.4484 26.25 11.9858 25.9548 10.6163 25.3644C9.24687 24.774 8.05562 23.9727 7.0425 22.9606C6.02938 21.9485 5.2274 20.7583 4.63656 19.39C4.04552 18.0219 3.75 16.5599 3.75 15.0041C3.75 13.4484 4.04521 11.9858 4.63562 10.6162C5.22604 9.24687 6.02729 8.05562 7.03937 7.0425C8.05146 6.02937 9.24167 5.2274 10.61 4.63656C11.9781 4.04552 13.4401 3.75 14.9959 3.75C16.5516 3.75 18.0142 4.04521 19.3838 4.63563C20.7531 5.22604 21.9444 6.02729 22.9575 7.03938C23.9706 8.05146 24.7726 9.24167 25.3634 10.61C25.9545 11.9781 26.25 13.4401 26.25 14.9959C26.25 16.5516 25.9548 18.0142 25.3644 19.3837C24.774 20.7531 23.9727 21.9444 22.9606 22.9575C21.9485 23.9706 20.7583 24.7726 19.39 25.3634C18.0219 25.9545 16.5599 26.25 15.0041 26.25ZM15 25C17.7917 25 20.1562 24.0312 22.0938 22.0938C24.0312 20.1562 25 17.7917 25 15C25 12.2083 24.0312 9.84375 22.0938 7.90625C20.1562 5.96875 17.7917 5 15 5C12.2083 5 9.84375 5.96875 7.90625 7.90625C5.96875 9.84375 5 12.2083 5 15C5 17.7917 5.96875 20.1562 7.90625 22.0938C9.84375 24.0312 12.2083 25 15 25Z" fill="#5D5FE3"/>
                  </svg></span></p>
                          
                  <p></p>
                  <Collapse in={open7} className=''>
                    <div id="example-collapse-text">
                     We always welcome your suggestion. Write to us via email.
                    </div>
                  </Collapse>
                    </div>

                    <div className=''>
                    <p className='d-flex justify-content-between border-bottom align-items-center m-0 border-top py-3' style={{cursor:'pointer'}} onClick={() => setOpen8(!open8)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open8}><span className='privacy-policy-text'>What should I do if my account is hacked?</span> <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path d="M14.375 20.625H15.625V15.625H20.625V14.375H15.625V9.375H14.375V14.375H9.375V15.625H14.375V20.625ZM15.0041 26.25C13.4484 26.25 11.9858 25.9548 10.6163 25.3644C9.24687 24.774 8.05562 23.9727 7.0425 22.9606C6.02938 21.9485 5.2274 20.7583 4.63656 19.39C4.04552 18.0219 3.75 16.5599 3.75 15.0041C3.75 13.4484 4.04521 11.9858 4.63562 10.6162C5.22604 9.24687 6.02729 8.05562 7.03937 7.0425C8.05146 6.02937 9.24167 5.2274 10.61 4.63656C11.9781 4.04552 13.4401 3.75 14.9959 3.75C16.5516 3.75 18.0142 4.04521 19.3838 4.63563C20.7531 5.22604 21.9444 6.02729 22.9575 7.03938C23.9706 8.05146 24.7726 9.24167 25.3634 10.61C25.9545 11.9781 26.25 13.4401 26.25 14.9959C26.25 16.5516 25.9548 18.0142 25.3644 19.3837C24.774 20.7531 23.9727 21.9444 22.9606 22.9575C21.9485 23.9706 20.7583 24.7726 19.39 25.3634C18.0219 25.9545 16.5599 26.25 15.0041 26.25ZM15 25C17.7917 25 20.1562 24.0312 22.0938 22.0938C24.0312 20.1562 25 17.7917 25 15C25 12.2083 24.0312 9.84375 22.0938 7.90625C20.1562 5.96875 17.7917 5 15 5C12.2083 5 9.84375 5.96875 7.90625 7.90625C5.96875 9.84375 5 12.2083 5 15C5 17.7917 5.96875 20.1562 7.90625 22.0938C9.84375 24.0312 12.2083 25 15 25Z" fill="#5D5FE3"/>
                  </svg></span></p>
                          
                  <p></p>
                  <Collapse in={open8} className=''>
                    <div id="example-collapse-text">
                    Immediately reset your password and contact support to secure your account.
                    </div>
                  </Collapse>
                    </div>
               
                </div>

            </div>
</div>

<Footer/>

<Backtotop/>
    </div>
  )
}

export default FAQ